/* --------------- variables --------------------- */
/* Break Point */
/* Color */
h1,
h2,
h3,
h4,
h5 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 700;
}

h2.fontEn {
  font-weight: 600;
  line-height: 1.2;
}

.mv {
  position: relative;
}
.mv .mvInner {
  position: relative;
  z-index: 1;
}
.mv .mvInner .mvWrap:before {
  content: "";
  position: absolute;
  background: url(../images/common/shape_orange.png) no-repeat 0 0/contain;
  z-index: -1;
}
.mv .mvInner .mvWrap .lead {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
}
.mv .mvInner .mvWrap h2 {
  letter-spacing: 0.1em;
}
.mv .mvInner .mvWrap p.fontEn {
  color: #747474;
  font-weight: 600;
}
.mv .mvInner .mainvisual img {
  border-radius: 20px 0 0 20px;
}
@media screen and (min-width: 1401px) {
  .mv .mvInner .mainvisual img {
    border-radius: 20px;
  }
}

.news {
  position: relative;
}
.news .commonInner {
  position: relative;
  z-index: 1;
}
.news .newsListWrap {
  border: 1px solid #DADADA;
  border-radius: 10px;
  margin-top: 25px;
  background-color: #fff;
  position: relative;
}
.news .newsListWrap:before {
  content: "";
  position: absolute;
  background: url(../images/common/shape_purple.png) no-repeat 0 0/contain;
  z-index: -1;
}
.news .newsListWrap .newsList li + li {
  position: relative;
}
.news .newsListWrap .newsList li + li:before {
  content: "";
  position: absolute;
  background-color: #DADADA;
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
}
.news .newsListWrap .newsList li a {
  display: block;
}
.news .newsListWrap .newsList li a .category {
  width: 96px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
}
.news .newsListWrap .newsList li a .category.pink {
  color: #FF6E6E;
  border: 1px solid #FF6E6E;
}
.news .newsListWrap .newsList li a .category.yellow {
  color: #FFB800;
  border: 1px solid #FFB800;
}
.news .newsListWrap .newsList li a .category.green {
  color: #55E19E;
  border: 1px solid #55E19E;
}
.news .newsListWrap .newsList li a .date {
  font-weight: 700;
  font-family: "Inter", sans-serif;
}
.news .newsListWrap .newsList li a .newsTitle {
  margin-top: 5px;
}
.news .newsListWrap .toArchive {
  text-align: right;
}
.news .newsListWrap .toArchive a {
  display: inline-block;
  position: relative;
  font-weight: 500;
}
.news .newsListWrap .toArchive a:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_black.svg) no-repeat 0 0/contain;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.aboutUs {
  position: relative;
}
.aboutUs .aboutUsInner {
  position: relative;
  z-index: 1;
}
.aboutUs .aboutUsInner .aboutMv {
  position: relative;
  display: flex;
}
.aboutUs .aboutUsInner .aboutMv:after {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0 20px 20px 0;
}
@media screen and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv:after {
    border-radius: 20px;
  }
}
.aboutUs .aboutUsInner .aboutMv .img {
  width: 50%;
  height: auto;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv .img:first-of-type {
    border-radius: 20px 0 0 20px;
  }
}
.aboutUs .aboutUsInner .aboutMv .img:nth-of-type(2) {
  border-radius: 0 20px 20px 0;
}
.aboutUs .aboutUsInner .aboutMv .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutUs .aboutUsInner .aboutMv .img p {
  position: absolute;
  color: #fff;
  z-index: 5;
}
.aboutUs .aboutUsInner .aboutMv .img p .name {
  font-weight: 700;
  font-size: 24px;
  display: block;
}
.aboutUs .aboutUsInner .aboutMv .img p .post {
  font-weight: 500;
  font-size: 12px;
  display: block;
}
.aboutUs .aboutUsInner .aboutWrap p.fontEn {
  color: #747474;
  font-weight: 600;
}
.aboutUs .aboutUsInner .aboutWrap h2 {
  margin-left: -0.5em;
}
.aboutUs .aboutUsInner .aboutWrap h2 span {
  letter-spacing: -0.2em;
}
.aboutUs .aboutUsInner .aboutWrap .description {
  margin-top: 25px;
  letter-spacing: 0em;
}

.lesson {
  position: relative;
}
.lesson .lessonInner {
  position: relative;
  z-index: 1;
}
.lesson .lessonInner .lessonMv {
  position: relative;
}
.lesson .lessonInner .lessonMv img {
  border-radius: 20px 0 0 20px;
}
@media screen and (min-width: 1401px) {
  .lesson .lessonInner .lessonMv img {
    border-radius: 20px;
  }
}
.lesson .lessonInner .lessonWrap p.fontEn {
  color: #747474;
  font-weight: 600;
}
.lesson .lessonInner .lessonWrap h2 {
  letter-spacing: 0;
}
.lesson .lessonInner .lessonWrap .description {
  margin-top: 25px;
  letter-spacing: 0em;
}

.aboutUs .moreBtn,
.lesson .moreBtn {
  margin-top: 40px;
}
.aboutUs .moreBtn a,
.lesson .moreBtn a {
  display: block;
  color: #fff;
  background: #000;
  height: 70px;
  line-height: 70px;
  border-radius: 5px;
  padding-inline: 35px;
  position: relative;
  text-align: center;
  border: 1px solid #000;
}
.aboutUs .moreBtn a:before,
.lesson .moreBtn a:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_white.svg) no-repeat 0 0/contain;
  top: 50%;
  right: 25px;
  transform: translate(0, -50%);
}
.course {
  background-color: #000;
  color: #fff;
}
.course a {
  color: #fff;
}
.course .courseList {
  display: flex;
  gap: 20px;
}
.course .courseList li {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.course .courseList li:nth-of-type(1) {
  border: 1px solid #FF6E6E;
  background-image: url(../images/home/course01_thum_sp.webp);
}
.course .courseList li:nth-of-type(2) {
  border: 1px solid #FFB800;
  background-image: url(../images/home/course02_thum_sp.webp);
}
.course .courseList li:nth-of-type(3) {
  border: 1px solid #55E19E;
  background-image: url(../images/home/course03_thum_sp.webp);
}
.course .courseList li:nth-of-type(4) {
  border: 1px solid #5585FF;
  background-image: url(../images/home/course04_thum_sp.webp);
}
.course .courseList li:before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.course .courseList li:after {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_white.svg) no-repeat 0 0/contain;
}
.course .courseList li a {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-weight: 700;
  padding: 2vw;
  position: relative;
  z-index: 1;
}
.course .courseList li a p {
  position: relative;
  z-index: 2;
  text-align: center;
}

.pageDoors {
  position: relative;
}
.pageDoors:before {
  content: "";
  position: absolute;
  background: url(../images/common/shape_green.png) no-repeat 0 0/contain;
}
.pageDoors ul {
  position: relative;
  z-index: 1;
}
.pageDoors ul li {
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.pageDoors ul li:nth-of-type(1) {
  background-image: url(../images/home/classroom_thum.webp);
}
.pageDoors ul li:nth-of-type(2) {
  background-image: url(../images/home/fee_thum.webp);
  margin-left: auto;
}
.pageDoors ul li:nth-of-type(3) {
  background-image: url(../images/home/voice_thum.webp);
}
.pageDoors ul li:before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.pageDoors ul li:after {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_white.svg) no-repeat 0 0/contain;
}
.pageDoors ul li a {
  display: block;
  color: #fff;
  position: relative;
  z-index: 1;
}
.pageDoors ul li a .wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.pageDoors ul li a .wrap p,
.pageDoors ul li a .wrap h3 {
  position: relative;
  z-index: 10;
}
.pageDoors ul li a .wrap p {
  font-weight: 600;
}
.pageDoors ul li a .wrap h3 {
  font-size: 32px;
  line-height: 1.2;
  margin-top: 10px;
}

.results {
  position: relative;
  position: relative;
  z-index: 1;
}
.results:before {
  content: "";
  position: absolute;
  background: #f8f8f8;
  width: 100%;
  bottom: 0;
  left: 0;
}
.results .resultsInner {
  margin-inline: auto;
  position: relative;
  z-index: 2;
}
.results .resultsInner p.fontEn {
  color: #747474;
  font-weight: 600;
}
.results .resultsInner h2 span {
  letter-spacing: -0.2em;
}
.results .resultsInner .container .resultsMv {
  position: relative;
}
@media screen and (min-width: 1401px) {
  .results .resultsInner .container .resultsMv {
    border-radius: 20px;
  }
}
.results .resultsInner .container .resultsWrap .best100 {
  width: 100%;
  max-width: 371px;
  height: auto;
}
.results .resultsInner .container .resultsWrap .description {
  letter-spacing: 0em;
}
.results .resultsInner .container .resultsWrap .toArchive {
  text-align: right;
}
.results .resultsInner .container .resultsWrap .toArchive a {
  display: inline-block;
  position: relative;
  font-weight: 500;
}
.results .resultsInner .container .resultsWrap .toArchive a:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_black.svg) no-repeat 0 0/contain;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.event {
  position: relative;
}
.event:before {
  content: "";
  position: absolute;
  background: #f8f8f8;
  width: 100%;
  bottom: 0;
  left: 0;
}
.event:after {
  content: "";
  position: absolute;
  background: url(../images/common/shape_blue.png) no-repeat 0 0/contain;
}
.event .eventInner {
  margin-inline: auto;
  position: relative;
  z-index: 1;
}
.event .eventInner p.fontEn {
  color: #747474;
  font-weight: 600;
}
.event .eventInner h2 span {
  letter-spacing: -0.2em;
}
@media screen and (min-width: 1401px) {
  .event .eventInner .container .eventMv {
    border-radius: 20px;
  }
}
.event .eventInner .container .eventWrap .description {
  letter-spacing: 0em;
}
.event .eventInner .container .eventWrap .toArchive {
  text-align: right;
}
.event .eventInner .container .eventWrap .toArchive a {
  display: inline-block;
  position: relative;
  font-weight: 500;
}
.event .eventInner .container .eventWrap .toArchive a:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_black.svg) no-repeat 0 0/contain;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.blog .commonInner {
  position: relative;
  z-index: 1;
}
.blog .blogListWrap {
  border: 1px solid #DADADA;
  border-radius: 10px;
  margin-top: 25px;
  background-color: #fff;
}
.blog .blogListWrap:before {
  content: "";
  position: absolute;
  background: url(../images/common/shape_yellow.png) no-repeat 0 0/contain;
  z-index: -1;
}
.blog .blogListWrap .blogList li + li {
  position: relative;
}
.blog .blogListWrap .blogList li + li:before {
  content: "";
  position: absolute;
  background-color: #DADADA;
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
}
.blog .blogListWrap .blogList li a {
  display: block;
}
.blog .blogListWrap .blogList li a .date {
  font-weight: 700;
  font-family: "Inter", sans-serif;
}
.blog .blogListWrap .blogList li a .blogTitle {
  margin-top: 5px;
}
.blog .blogListWrap .toArchive {
  text-align: right;
}
.blog .blogListWrap .toArchive a {
  display: inline-block;
  position: relative;
  font-weight: 500;
}
.blog .blogListWrap .toArchive a:before {
  content: "";
  position: absolute;
  background: url(../images/common/arrow_black.svg) no-repeat 0 0/contain;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.instagram {
  position: relative;
}
.instagram:before {
  content: "";
  position: absolute;
  background: #f8f8f8;
  width: 100%;
  bottom: 0;
  left: 0;
}
.instagram .commonInner {
  position: relative;
  z-index: 1;
}
.instagram picture img {
  display: block;
  margin-top: 30px;
}

@media screen and (max-width: 1023px) {
  .mv {
    padding-top: 20px;
  }
  .mv .mvInner .mvWrap .lead {
    font-size: 18px;
    padding-inline: 30px;
  }
  .mv .mvInner .mvWrap h2 {
    font-size: 46px;
    line-height: 1.43;
    padding-inline: 30px;
    margin-top: 5px;
  }
  .mv .mvInner .mvWrap p.fontEn {
    font-size: 16px;
    padding-inline: 30px;
    margin-top: 15px;
  }
  .mv .mvInner .mainvisual {
    width: calc(100% - 30px);
    margin-top: 30px;
    margin-left: auto;
  }
}
@media screen and (max-width: 1023px) and (min-width: 1401px) {
  .mv .mvInner .mainvisual img {
    border-radius: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .aboutUs {
    padding-block: 80px 60px;
  }
  .aboutUs:before {
    content: "";
    position: absolute;
    background: #f8f8f8;
    width: 100%;
    height: 55.1%;
    bottom: 0;
    left: 0;
  }
  .aboutUs .aboutUsInner .aboutMv {
    width: calc(100% - 30px);
    margin-top: 30px;
  }
  .aboutUs .aboutUsInner .aboutMv.pc {
    display: none;
  }
}
@media screen and (max-width: 1023px) and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv:after {
    border-radius: 20px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv .img:first-of-type {
    border-radius: 20px 0 0 20px;
  }
}
@media screen and (max-width: 1023px) {
  .aboutUs .aboutUsInner .aboutWrap p.fontEn {
    font-size: 16px;
    padding-inline: 30px;
  }
  .aboutUs .aboutUsInner .aboutWrap h2 {
    font-size: 32px;
    line-height: 1.43;
    letter-spacing: 0;
    padding-inline: 30px;
    margin-top: 5px;
  }
  .aboutUs .aboutUsInner .aboutWrap .description {
    padding-inline: 30px;
    line-height: 2.28;
  }
  .lesson {
    padding-block: 60px;
  }
  .lesson:before {
    content: "";
    position: absolute;
    background: #f8f8f8;
    width: 100%;
    height: 44.6%;
    bottom: 0;
    left: 0;
  }
  .lesson .lessonInner .lessonMv {
    width: calc(100% - 30px);
    margin-top: 30px;
    margin-left: auto;
  }
  .lesson .lessonInner .lessonMv.pc {
    display: none;
  }
}
@media screen and (max-width: 1023px) and (min-width: 1401px) {
  .lesson .lessonInner .lessonMv img {
    border-radius: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .lesson .lessonInner .lessonMv:before {
    content: "";
    position: absolute;
    background: url(../images/common/shape_pink.png) no-repeat 0 0/contain;
    z-index: -1;
    width: 296px;
    height: 298px;
    right: -105px;
    top: -180px;
    transform: rotate(35deg);
  }
  .lesson .lessonInner .lessonWrap p.fontEn {
    font-size: 16px;
    padding-inline: 30px;
  }
  .lesson .lessonInner .lessonWrap h2 {
    font-size: 32px;
    line-height: 1.43;
    padding-inline: 30px;
    margin-top: 5px;
  }
  .lesson .lessonInner .lessonWrap .description {
    padding-inline: 30px;
    line-height: 2.28;
  }
  .aboutUs .moreBtn,
  .lesson .moreBtn {
    padding-inline: 30px;
  }
  .aboutUs .moreBtn a:before,
  .lesson .moreBtn a:before {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 1401px) {
  .results .resultsInner .container .resultsMv {
    border-radius: 20px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 1401px) {
  .event .eventInner .container .eventMv {
    border-radius: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .mv {
    max-width: 1400px;
    margin-inline: auto;
    padding-top: 100px;
  }
  .mv .mvInner {
    display: flex;
    justify-content: flex-end;
  }
  .mv .mvInner .mvWrap {
    width: 48.58%;
    width: 680px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: 9.285% 7.14%;
  }
  .mv .mvInner .mvWrap .lead {
    font-size: 20px;
  }
  .mv .mvInner .mvWrap h2 {
    font-size: 68px;
    line-height: 1.35;
    margin-top: 10px;
  }
  .mv .mvInner .mvWrap p.fontEn {
    font-size: 20px;
    margin-top: 30px;
  }
  .mv .mvInner .mainvisual {
    width: calc(100% - 680px);
  }
}
@media screen and (min-width: 1024px) and (min-width: 1401px) {
  .mv .mvInner .mainvisual img {
    border-radius: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .aboutUs {
    max-width: 1400px;
    margin-inline: auto;
    padding-top: 120px;
  }
  .aboutUs .aboutUsInner {
    display: flex;
  }
  .aboutUs .aboutUsInner .aboutMv {
    width: min(51.5vw, 720px);
    width: calc(100% - 680px);
  }
  .aboutUs .aboutUsInner .aboutMv.sp {
    display: none;
  }
  .aboutUs .aboutUsInner .aboutMv:before {
    content: "";
    position: absolute;
    background: url(../images/common/shape_pink.png) no-repeat 0 0/contain;
    z-index: -1;
    width: min(32.71vw, 458px);
    height: min(32.57vw, 456px);
    bottom: max(-230px, -16.42vw);
    left: min(14.28vw, 200px);
  }
}
@media screen and (min-width: 1024px) and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv:after {
    border-radius: 20px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv .img:first-of-type {
    border-radius: 20px 0 0 20px;
  }
}
@media screen and (min-width: 1024px) {
  .aboutUs .aboutUsInner .aboutWrap {
    width: min(48.5vw, 680px);
    width: 680px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: 7.14% 12.1%;
  }
  .aboutUs .aboutUsInner .aboutWrap p.fontEn {
    font-size: 20px;
  }
  .aboutUs .aboutUsInner .aboutWrap h2 {
    font-size: 40px;
    line-height: 1.35;
  }
  .aboutUs .aboutUsInner .aboutWrap .description {
    line-height: 2.25;
  }
  .lesson {
    max-width: 1400px;
    margin-inline: auto;
    padding-block: 120px;
  }
  .lesson .lessonInner {
    display: flex;
    justify-content: flex-end;
  }
  .lesson .lessonInner .lessonMv {
    width: calc(100% - 680px);
  }
  .lesson .lessonInner .lessonMv.sp {
    display: none;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1401px) {
  .lesson .lessonInner .lessonMv img {
    border-radius: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .lesson .lessonInner .lessonWrap {
    width: 48.58%;
    width: 680px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: 12.1% 7.14%;
  }
  .lesson .lessonInner .lessonWrap p.fontEn {
    font-size: 20px;
  }
  .lesson .lessonInner .lessonWrap h2 {
    font-size: 40px;
    line-height: 1.35;
  }
  .lesson .lessonInner .lessonWrap .description {
    line-height: 2.25;
  }
  .aboutUs .moreBtn a,
  .lesson .moreBtn a {
    transition: all 0.3s ease;
  }
  .aboutUs .moreBtn a:before,
  .lesson .moreBtn a:before {
    width: 24px;
    height: 24px;
  }
  .aboutUs .moreBtn a:hover,
  .lesson .moreBtn a:hover {
    color: #000;
    background: #fff;
  }
  .aboutUs .moreBtn a:hover:before,
  .lesson .moreBtn a:hover:before {
    background: url(../images/common/arrow_black.svg) no-repeat 0 0/contain;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1401px) {
  .results .resultsInner .container .resultsMv {
    border-radius: 20px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1401px) {
  .event .eventInner .container .eventMv {
    border-radius: 20px;
  }
}
@media screen and (max-width: 768px) {
  .commonInner {
    width: calc(100% - 60px);
    margin-inline: auto;
  }
  h2.fontEn {
    font-size: 30px;
  }
  .mv .mvInner .mvWrap:before {
    width: 232px;
    height: 254px;
    right: -60px;
    top: 115px;
  }
}
@media screen and (max-width: 768px) and (min-width: 1401px) {
  .mv .mvInner .mainvisual img {
    border-radius: 20px;
  }
}
@media screen and (max-width: 768px) {
  .news {
    margin-top: 50px;
  }
  .news .newsListWrap {
    padding-block: 25px;
    padding-inline: 25px;
  }
  .news .newsListWrap:before {
    width: 290px;
    height: 306px;
    left: -100px;
    bottom: -130px;
    transform: rotate(-186deg);
  }
  .news .newsListWrap .newsList li + li {
    margin-top: 18px;
    padding-top: 20px;
  }
  .news .newsListWrap .newsList li a .category {
    height: 20px;
    line-height: 18px;
    margin-right: 10px;
  }
  .news .newsListWrap .newsList li a .date {
    font-size: 16px;
  }
  .news .newsListWrap .toArchive {
    margin-top: 30px;
  }
  .news .newsListWrap .toArchive a {
    padding-left: 30px;
    line-height: 20px;
  }
  .news .newsListWrap .toArchive a:before {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 768px) and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv:after {
    border-radius: 20px;
  }
}
@media screen and (max-width: 768px) and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv .img:first-of-type {
    border-radius: 20px 0 0 20px;
  }
}
@media screen and (max-width: 768px) {
  .aboutUs .aboutUsInner .aboutMv .img p {
    bottom: 20px;
    left: 20px;
  }
}
@media screen and (max-width: 768px) and (min-width: 1401px) {
  .lesson .lessonInner .lessonMv img {
    border-radius: 20px;
  }
}
@media screen and (max-width: 768px) {
  .course {
    padding-block: 55px 60px;
  }
  .course .courseList {
    margin-top: 40px;
    flex-direction: column;
  }
  .course .courseList li {
    height: 100px;
    background-size: cover;
  }
  .course .courseList li:after {
    width: 20px;
    height: 20px;
    right: 15px;
    bottom: 15px;
  }
  .course .courseList li a {
    height: 100px;
  }
  .pageDoors {
    margin-top: 60px;
  }
  .pageDoors:before {
    width: 444px;
    height: 450px;
    left: -127px;
    bottom: -244px;
    transform: rotate(-25deg);
  }
  .pageDoors ul li {
    width: calc(100% - 30px);
    background-size: cover;
  }
  .pageDoors ul li + li {
    margin-top: 40px;
  }
  .pageDoors ul li:nth-of-type(1) {
    border-radius: 0 20px 20px 0;
  }
  .pageDoors ul li:nth-of-type(2) {
    border-radius: 20px 0 0 20px;
  }
  .pageDoors ul li:nth-of-type(3) {
    border-radius: 0 20px 20px 0;
  }
  .pageDoors ul li:after {
    width: 36px;
    height: 36px;
    right: 27px;
    bottom: 27px;
  }
  .pageDoors ul li a {
    height: 290px;
    padding: 27px 72px 27px 27px;
  }
  .results {
    padding-block: 60px;
  }
  .results:before {
    height: 65%;
  }
  .results .resultsInner {
    width: calc(100% - 60px);
  }
  .results .resultsInner p.fontEn {
    font-size: 16px;
  }
  .results .resultsInner h2 {
    font-size: 32px;
    line-height: 1.43;
    letter-spacing: 0;
    margin-top: 5px;
  }
  .results .resultsInner .container {
    margin-top: 28px;
  }
  .results .resultsInner .container .resultsMv {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) and (min-width: 1401px) {
  .results .resultsInner .container .resultsMv {
    border-radius: 20px;
  }
}
@media screen and (max-width: 768px) {
  .results .resultsInner .container .resultsMv img {
    aspect-ratio: 370/150;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 50% 10%;
    border-radius: 10px;
  }
  .results .resultsInner .container .resultsWrap .best100 {
    margin-top: 30px;
  }
  .results .resultsInner .container .resultsWrap .description {
    margin-top: 12px;
    line-height: 2.28;
  }
  .results .resultsInner .container .resultsWrap .toArchive {
    margin-top: 25px;
  }
  .results .resultsInner .container .resultsWrap .toArchive a {
    padding-left: 30px;
    line-height: 20px;
  }
  .results .resultsInner .container .resultsWrap .toArchive a:before {
    width: 20px;
    height: 20px;
  }
  .event {
    padding-block: 60px;
  }
  .event:before {
    height: 55.9%;
  }
  .event:after {
    width: 296px;
    height: 298px;
    right: -115px;
    top: -130px;
    transform: rotate(140deg) rotateY(180deg);
  }
  .event .eventInner {
    width: calc(100% - 60px);
  }
  .event .eventInner p.fontEn {
    font-size: 16px;
  }
  .event .eventInner h2 {
    font-size: 32px;
    line-height: 1.43;
    letter-spacing: 0;
    margin-top: 5px;
  }
  .event .eventInner .container {
    margin-top: 28px;
  }
  .event .eventInner .container .eventMv {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) and (min-width: 1401px) {
  .event .eventInner .container .eventMv {
    border-radius: 20px;
  }
}
@media screen and (max-width: 768px) {
  .event .eventInner .container .eventMv img {
    aspect-ratio: 370/150;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 50% 10%;
    border-radius: 10px;
  }
  .event .eventInner .container .eventWrap .description {
    margin-top: 25px;
    line-height: 2.28;
  }
  .event .eventInner .container .eventWrap .toArchive {
    margin-top: 25px;
  }
  .event .eventInner .container .eventWrap .toArchive a {
    padding-left: 30px;
    line-height: 20px;
  }
  .event .eventInner .container .eventWrap .toArchive a:before {
    width: 20px;
    height: 20px;
  }
  .blog {
    padding-top: 60px;
  }
  .blog .blogListWrap {
    padding-block: 25px;
    padding-inline: 25px;
  }
  .blog .blogListWrap:before {
    width: 250px;
    height: 260px;
    left: -100px;
    bottom: -95px;
    left: -100px;
    bottom: -90px;
    transform: rotate(-210deg);
  }
  .blog .blogListWrap .blogList li + li {
    margin-top: 18px;
    padding-top: 20px;
  }
  .blog .blogListWrap .blogList li a .date {
    font-size: 16px;
  }
  .blog .blogListWrap .toArchive {
    margin-top: 30px;
  }
  .blog .blogListWrap .toArchive a {
    padding-left: 30px;
    line-height: 20px;
  }
  .blog .blogListWrap .toArchive a:before {
    width: 20px;
    height: 20px;
  }
  .instagram {
    padding-block: 60px;
  }
  .instagram:before {
    height: 44.6%;
  }
}
@media print, screen and (min-width: 769px) {
  .commonInner {
    max-width: 1000px;
    width: calc(100% - 120px);
    margin: 0 auto;
  }
  h2.fontEn {
    font-size: 50px;
  }
  .mv .mvInner .mvWrap:before {
    width: 392px;
    height: 430px;
    left: -129px;
    bottom: -120px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1401px) {
  .mv .mvInner .mainvisual img {
    border-radius: 20px;
  }
}
@media print, screen and (min-width: 769px) {
  .news {
    padding-block: 120px 100px;
  }
  .news:before {
    content: "";
    position: absolute;
    background: #f8f8f8;
    width: 100%;
    height: 150px;
    bottom: 0;
    left: 0;
  }
  .news .newsListWrap {
    padding-block: 45px;
    padding-inline: 65px;
  }
  .news .newsListWrap:before {
    width: 290px;
    height: 306px;
    top: -140px;
    right: -135px;
  }
  .news .newsListWrap .newsList li + li {
    margin-top: 28px;
    padding-top: 30px;
  }
  .news .newsListWrap .newsList li a {
    transition: all 0.3s ease;
  }
  .news .newsListWrap .newsList li a:hover {
    opacity: 0.5;
  }
  .news .newsListWrap .newsList li a .category {
    height: 23px;
    line-height: 21px;
    margin-right: 20px;
  }
  .news .newsListWrap .newsList li a .date {
    font-size: 18px;
  }
  .news .newsListWrap .toArchive {
    margin-top: 40px;
  }
  .news .newsListWrap .toArchive a {
    padding-left: 38px;
    line-height: 24px;
    transition: all 0.3s ease;
  }
  .news .newsListWrap .toArchive a:before {
    width: 24px;
    height: 24px;
  }
  .news .newsListWrap .toArchive a:hover {
    opacity: 0.5;
  }
}
@media screen and (min-width: 769px) and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv:after {
    border-radius: 20px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv .img:first-of-type {
    border-radius: 20px 0 0 20px;
  }
}
@media print, screen and (min-width: 769px) {
  .aboutUs .aboutUsInner .aboutMv .img p {
    bottom: 30px;
    left: 30px;
  }
}
@media screen and (min-width: 769px) and (min-width: 1401px) {
  .lesson .lessonInner .lessonMv img {
    border-radius: 20px;
  }
}
@media print, screen and (min-width: 769px) {
  .course {
    padding-block: 95px 120px;
  }
  .course .courseList {
    margin-top: 45px;
    flex-wrap: wrap;
  }
  .course .courseList li {
    width: calc((100% - 40px) / 2);
    height: 130px;
    background-size: 100% auto;
    transition: all 0.3s ease;
  }
  .course .courseList li:hover {
    background-size: 110% auto;
  }
  .course .courseList li:after {
    width: 30px;
    height: 30px;
    right: 20px;
    bottom: 20px;
  }
  .course .courseList li a {
    height: 130px;
    transition: all 0.3s ease;
  }
  .pageDoors {
    background: #f8f8f8;
  }
  .pageDoors:before {
    width: 494px;
    height: 500px;
    bottom: -280px;
    right: min(19.2vw, 270px);
  }
  .pageDoors ul {
    display: flex;
    max-width: 1400px;
    margin-inline: auto;
  }
  .pageDoors ul li {
    width: 33.3333333333%;
    background-size: auto 100%;
    transition: all 0.3s ease;
  }
  .pageDoors ul li:hover {
    background-size: auto 110%;
  }
  .pageDoors ul li:after {
    width: 36px;
    height: 36px;
    right: 30px;
    bottom: 30px;
  }
  .pageDoors ul li a {
    height: 400px;
    padding: 30px 72px 30px 30px;
    transition: background-image 0.3s ease;
  }
  .pageDoors ul li a .wrap p {
    margin-top: auto;
  }
  .results {
    padding-top: 120px;
  }
  .results .resultsInner {
    width: calc(100% - 120px);
    max-width: 1060px;
  }
  .results .resultsInner p.fontEn {
    font-size: 20px;
  }
  .results .resultsInner h2 {
    font-size: 40px;
    line-height: 1.35;
  }
  .results .resultsInner .container {
    display: flex;
    margin-top: 32px;
  }
  .results .resultsInner .container .resultsMv {
    width: 47.16%;
    border-radius: 0 20px 20px 0;
    box-shadow: -170px 50px 0 0 #f8f8f8;
  }
}
@media screen and (min-width: 769px) and (min-width: 1401px) {
  .results .resultsInner .container .resultsMv {
    border-radius: 20px;
  }
}
@media print, screen and (min-width: 769px) {
  .results .resultsInner .container .resultsMv:before {
    content: "";
    position: absolute;
    background: url(../images/common/shape_blue.png) no-repeat 0 0/contain;
    z-index: -1;
    width: 238px;
    height: 233px;
    bottom: -200px;
    left: 42px;
  }
  .results .resultsInner .container .resultsMv img {
    border-radius: 20px;
  }
  .results .resultsInner .container .resultsWrap {
    width: 52.84%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 9.43%;
  }
  .results .resultsInner .container .resultsWrap .description {
    margin-top: 15px;
    line-height: 2.25;
  }
  .results .resultsInner .container .resultsWrap .toArchive {
    margin-top: 30px;
  }
  .results .resultsInner .container .resultsWrap .toArchive a {
    padding-left: 38px;
    line-height: 24px;
    transition: all 0.3s ease;
  }
  .results .resultsInner .container .resultsWrap .toArchive a:before {
    width: 24px;
    height: 24px;
  }
  .results .resultsInner .container .resultsWrap .toArchive a:hover {
    opacity: 0.5;
  }
  .event {
    padding-block: 50px;
  }
  .event .eventInner {
    width: calc(100% - 120px);
    max-width: 1060px;
    margin-top: 120px;
  }
  .event .eventInner .titileWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .event .eventInner p.fontEn {
    width: 47.16%;
    font-size: 20px;
  }
  .event .eventInner h2 {
    width: 47.16%;
    font-size: 40px;
    line-height: 1.35;
  }
  .event .eventInner .container {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 32px;
  }
  .event .eventInner .container .eventMv {
    width: 47.16%;
    border-radius: 20px 0 0 20px;
    box-shadow: 170px 50px 0 0 #f8f8f8;
  }
}
@media screen and (min-width: 769px) and (min-width: 1401px) {
  .event .eventInner .container .eventMv {
    border-radius: 20px;
  }
}
@media print, screen and (min-width: 769px) {
  .event .eventInner .container .eventMv img {
    border-radius: 20px;
  }
  .event .eventInner .container .eventWrap {
    width: 52.84%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 9.43%;
  }
  .event .eventInner .container .eventWrap .description {
    line-height: 2.25;
  }
  .event .eventInner .container .eventWrap .toArchive {
    margin-top: 30px;
  }
  .event .eventInner .container .eventWrap .toArchive a {
    padding-left: 38px;
    line-height: 24px;
    transition: all 0.3s ease;
  }
  .event .eventInner .container .eventWrap .toArchive a:before {
    width: 24px;
    height: 24px;
  }
  .event .eventInner .container .eventWrap .toArchive a:hover {
    opacity: 0.5;
  }
  .blog {
    padding-top: 100px;
  }
  .blog .blogListWrap {
    padding-block: 45px;
    padding-inline: 65px;
  }
  .blog .blogListWrap:before {
    width: 290px;
    height: 306px;
    top: 60px;
    right: 70px;
    top: -50px;
    right: -135px;
  }
  .blog .blogListWrap .blogList li + li {
    margin-top: 28px;
    padding-top: 30px;
  }
  .blog .blogListWrap .blogList li a {
    transition: all 0.3s ease;
  }
  .blog .blogListWrap .blogList li a:hover {
    opacity: 0.5;
  }
  .blog .blogListWrap .blogList li a .date {
    font-size: 18px;
  }
  .blog .blogListWrap .toArchive {
    margin-top: 40px;
  }
  .blog .blogListWrap .toArchive a {
    padding-left: 38px;
    line-height: 24px;
    transition: all 0.3s ease;
  }
  .blog .blogListWrap .toArchive a:before {
    width: 24px;
    height: 24px;
  }
  .blog .blogListWrap .toArchive a:hover {
    opacity: 0.5;
  }
  .instagram {
    padding-block: 120px;
  }
  .instagram:before {
    height: 42.2%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) and (min-width: 1401px) {
  .mv .mvInner .mainvisual img {
    border-radius: 20px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv:after {
    border-radius: 20px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) and (min-width: 1401px) {
  .aboutUs .aboutUsInner .aboutMv .img:first-of-type {
    border-radius: 20px 0 0 20px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) and (min-width: 1401px) {
  .lesson .lessonInner .lessonMv img {
    border-radius: 20px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) and (min-width: 1401px) {
  .results .resultsInner .container .resultsMv {
    border-radius: 20px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) and (min-width: 1401px) {
  .event .eventInner .container .eventMv {
    border-radius: 20px;
  }
}