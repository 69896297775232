.commonInner {
  @if $type =="" {}

  @if $type ==sp {
    width: calc(100% - 60px);
    margin-inline: auto;
  }

  @if $type ==pc {
    @include maxPC;
  }
}

h1,
h2,
h3,
h4,
h5 {
  @if $type =="" {
    @include fontJa;
    font-weight: 700;
  }
}

h2.fontEn {
  @if $type =="" {
    font-weight: 600;
    line-height: 1.2;
  }

  @if $type ==sp {
    font-size: 30px;
  }

  @if $type ==pc {
    font-size: 50px;
  }
}

.mv {
  @if $type =="" {
    position: relative;
  }

  @if $type ==header_sp {
    padding-top: 20px;
  }

  @if $type ==header_pc {
    max-width: 1400px;
    margin-inline: auto;
    padding-top: 100px;
  }

  .mvInner {
    @if $type =="" {
      position: relative;
      z-index: 1;
    }

    @if $type ==header_sp {}

    @if $type ==header_pc {
      display: flex;
      justify-content: flex-end;
    }

    .mvWrap {
      @if $type =="" {}

      @if $type ==header_sp {}

      @if $type ==header_pc {
        width: 48.58%;
        width: 680px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-inline: 9.285% 7.14%;
      }

      &:before {
        @if $type =="" {
          content: "";
          position: absolute;
          background: url(../images/common/shape_orange.png) no-repeat 0 0 / contain;
          z-index: -1;
        }

        @if $type ==sp {
          width: 232px;
          height: 254px;
          right: -60px;
          top: 115px;
        }

        @if $type ==pc {
          width: 392px;
          height: 430px;
          left: -129px;
          bottom: -120px
        }
      }

      .lead {
        @if $type =="" {
          @include fontJa;
          font-weight: 500;
          letter-spacing: 0.05em;
        }

        @if $type ==header_sp {
          font-size: 18px;
          padding-inline: 30px;
        }

        @if $type ==header_pc {
          font-size: 20px;
        }
      }

      h2 {
        @if $type =="" {
          letter-spacing: 0.1em;
        }

        @if $type ==header_sp {
          font-size: 46px;
          line-height: 1.43;
          padding-inline: 30px;
          margin-top: 5px;
        }

        @if $type ==header_pc {
          font-size: 68px;
          line-height: 1.35;
          margin-top: 10px;
        }
      }

      p.fontEn {
        @if $type =="" {
          color: #747474;
          font-weight: 600;
        }

        @if $type ==header_sp {
          font-size: 16px;
          padding-inline: 30px;
          margin-top: 15px;
        }

        @if $type ==header_pc {
          font-size: 20px;
          margin-top: 30px;
        }
      }
    }

    .mainvisual {
      @if $type =="" {}

      @if $type ==header_sp {
        width: calc(100% - 30px);
        margin-top: 30px;
        margin-left: auto;
      }

      @if $type ==header_pc {
        width: calc(100% - 680px);
      }

      img {
        @if $type =="" {
          border-radius: 20px 0 0 20px;
        }

        @media screen and (min-width: 1401px) {
          border-radius: 20px;
        }
      }
    }
  }
}

.news {
  @if $type =="" {
    position: relative;
  }

  @if $type ==sp {
    margin-top: 50px;
  }

  @if $type ==pc {
    padding-block: 120px 100px;
  }

  &:before {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
      content: "";
      position: absolute;
      background: $gray;
      width: 100%;
      height: 150px;
      bottom: 0;
      left: 0;
    }
  }

  .commonInner {
    @if $type =="" {
      position: relative;
      z-index: 1;
    }
  }

  h2 {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}
  }

  .newsListWrap {
    @if $type =="" {
      border: 1px solid #DADADA;
      border-radius: 10px;
      margin-top: 25px;
      background-color: #fff;
      position: relative;
    }

    @if $type ==sp {
      padding-block: 25px;
      padding-inline: 25px;
    }

    @if $type ==pc {
      padding-block: 45px;
      padding-inline: 65px;
    }

    &:before {
      @if $type =="" {
        content: "";
        position: absolute;
        background: url(../images/common/shape_purple.png) no-repeat 0 0 / contain;
        z-index: -1;
      }

      @if $type ==sp {
        width: 290px;
        height: 306px;
        left: -100px;
        bottom: -130px;
        transform: rotate(-186deg);
      }

      @if $type ==pc {
        width: 290px;
        height: 306px;
        top: -140px;
        right: -135px;
      }
    }

    .newsList {
      @if $type =="" {}

      @if $type ==sp {}

      @if $type ==pc {}

      li {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {}

        +li {
          @if $type =="" {
            position: relative;
          }

          @if $type ==sp {
            margin-top: 18px;
            padding-top: 20px;
          }

          @if $type ==pc {
            margin-top: 28px;
            padding-top: 30px;
          }

          &:before {
            @if $type =="" {
              content: "";
              position: absolute;
              background-color: #DADADA;
              width: 100%;
              height: 1px;
              top: 0;
              left: 0;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }

        }

        a {
          @if $type =="" {
            display: block;
          }

          @if $type ==sp {}

          @if $type ==pc {
            transition: all .3s ease;
          }

          &:hover {
            @if $type ==pc {
              opacity: .5;
            }
          }

          .category {
            @if $type =="" {
              width: 96px;
              display: inline-block;
              text-align: center;
              font-size: 14px;
              border-radius: 5px;
            }

            @if $type ==sp {
              height: 20px;
              line-height: 18px;
              margin-right: 10px;
            }

            @if $type ==pc {
              height: 23px;
              line-height: 21px;
              margin-right: 20px;
            }

            &.pink {
              @if $type =="" {
                color: $pink;
                border: 1px solid $pink;
              }
            }

            &.yellow {
              @if $type =="" {
                color: $yellow;
                border: 1px solid $yellow;
              }
            }

            &.green {
              @if $type =="" {
                color: $green;
                border: 1px solid $green;
              }
            }
          }

          .date {
            @if $type =="" {
              font-weight: 700;
              @include fontEn;
            }

            @if $type ==sp {
              font-size: 16px;
            }

            @if $type ==pc {
              font-size: 18px;
            }
          }

          .newsTitle {
            @if $type =="" {
              margin-top: 5px;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }
        }
      }
    }

    .toArchive {
      @if $type =="" {
        text-align: right;
      }

      @if $type ==sp {
        margin-top: 30px;
      }

      @if $type ==pc {
        margin-top: 40px;
      }

      a {
        @if $type =="" {
          display: inline-block;
          position: relative;
          font-weight: 500;
        }

        @if $type ==sp {
          padding-left: 30px;
          line-height: 20px;
        }

        @if $type ==pc {
          padding-left: 38px;
          line-height: 24px;
          transition: all .3s ease;
        }

        &:before {
          @if $type =="" {
            content: "";
            position: absolute;
            background: url(../images/common/arrow_black.svg) no-repeat 0 0 / contain;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
          }

          @if $type ==sp {
            width: 20px;
            height: 20px;
          }

          @if $type ==pc {
            width: 24px;
            height: 24px;
          }
        }

        &:hover {
          @if $type ==pc {
            opacity: .5;
          }
        }
      }
    }
  }
}


.aboutUs {
  @if $type =="" {
    position: relative;
  }

  @if $type ==header_sp {
    padding-block: 80px 60px;
  }

  @if $type ==header_pc {
    max-width: 1400px;
    margin-inline: auto;
    padding-top: 120px;
  }

  &:before {
    @if $type =="" {}

    @if $type ==header_sp {
      content: "";
      position: absolute;
      background: $gray;
      width: 100%;
      height: 55.1%;
      bottom: 0;
      left: 0;
    }

    @if $type ==header_pc {}
  }

  .aboutUsInner {
    @if $type =="" {
      position: relative;
      z-index: 1;
    }

    @if $type ==header_sp {}

    @if $type ==header_pc {
      display: flex;
    }

    .aboutMv {
      @if $type =="" {
        position: relative;
        display: flex;
      }

      @if $type ==header_sp {
        width: calc(100% - 30px);
        margin-top: 30px;
      }

      @if $type ==header_pc {
        width: min(51.5vw, 720px);
        width: calc(100% - 680px);
      }

      &.sp {
        @if $type ==header_pc {
          display: none;
        }
      }

      &.pc {
        @if $type ==header_sp {
          display: none;
        }
      }

      &:before {
        @if $type ==header_pc {
          content: "";
          position: absolute;
          background: url(../images/common/shape_pink.png) no-repeat 0 0 / contain;
          z-index: -1;
          width: min(32.71vw, 458px);
          height: min(32.57vw, 456px);
          bottom: max(-230px, -16.42vw);
          left: min(14.28vw, 200px);
        }
      }


      &:after {
        @if $type =="" {
          content: "";
          position: absolute;
          background: rgba(0, 0, 0, 0.3);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 0 20px 20px 0;
        }

        @media screen and (min-width: 1401px) {
          border-radius: 20px;
        }
      }

      .img {
        @if $type =="" {
          width: 50%;
          height: auto;
          overflow: hidden;
          position: relative;
        }

        @if $type ==sp {}

        @if $type ==pc {}

        &:first-of-type {
          @media screen and (min-width: 1401px) {
            border-radius: 20px 0 0 20px;
          }
        }

        &:nth-of-type(2) {
          @if $type =="" {
            border-radius: 0 20px 20px 0;
          }
        }

        img {
          @if $type =="" {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          @if $type ==sp {}

          @if $type ==pc {}
        }

        p {
          @if $type =="" {
            position: absolute;
            color: #fff;
            z-index: 5;
          }

          @if $type ==sp {
            bottom: 20px;
            left: 20px;
          }

          @if $type ==pc {
            bottom: 30px;
            left: 30px;
          }

          .name {
            @if $type =="" {
              font-weight: 700;
              font-size: 24px;
              display: block;
            }
            @if $type ==sp {
            }
            @if $type ==pc {
            }
          }

          .post {
            @if $type =="" {
              font-weight: 500;
              font-size: 12px;
              display: block;
            }
            @if $type ==sp {
            }
            @if $type ==pc {
            }
          }
        }
      }
    }

    .aboutWrap {
      @if $type =="" {}

      @if $type ==header_sp {}

      @if $type ==header_pc {
        width: min(48.5vw, 680px);
        width: 680px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-inline: 7.14% 12.1%;
      }

      p.fontEn {
        @if $type =="" {
          color: #747474;
          font-weight: 600;
        }

        @if $type ==header_sp {
          font-size: 16px;
          padding-inline: 30px;
        }

        @if $type ==header_pc {
          font-size: 20px;
        }
      }

      h2 {
        @if $type =="" {
          margin-left: -0.5em;
        }

        @if $type ==header_sp {
          font-size: 32px;
          line-height: 1.43;
          letter-spacing: 0;
          padding-inline: 30px;
          margin-top: 5px;
        }

        @if $type ==header_pc {
          font-size: 40px;
          line-height: 1.35;
        }

        span {
          @if $type =="" {
            letter-spacing: -0.2em;
          }

          @if $type ==header_sp {}

          @if $type ==header_pc {}
        }
      }

      .description {
        @if $type =="" {
          margin-top: 25px;
          letter-spacing: 0em;
        }

        @if $type ==header_sp {
          padding-inline: 30px;
          line-height: 2.28;
        }

        @if $type ==header_pc {
          line-height: 2.25;
        }
      }
    }
  }
}



.lesson {
  @if $type =="" {
    position: relative;
  }

  @if $type ==header_sp {
    padding-block: 60px;
  }

  @if $type ==header_pc {
    max-width: 1400px;
    margin-inline: auto;
    padding-block: 120px;
  }

  &:before {
    @if $type =="" {}

    @if $type ==header_sp {
      content: "";
      position: absolute;
      background: $gray;
      width: 100%;
      height: 44.6%;
      bottom: 0;
      left: 0;
    }

    @if $type ==header_pc {}
  }

  .lessonInner {
    @if $type =="" {
      position: relative;
      z-index: 1;
    }

    @if $type ==header_sp {}

    @if $type ==header_pc {
      display: flex;
      justify-content: flex-end;
    }

    .lessonMv {
      @if $type =="" {
        position: relative;
      }

      @if $type ==header_sp {
        width: calc(100% - 30px);
        margin-top: 30px;
        margin-left: auto;
      }

      @if $type ==header_pc {
        width: calc(100% - 680px);
      }

      &.sp {
        @if $type ==header_pc {
          display: none;
        }
      }

      &.pc {
        @if $type ==header_sp {
          display: none;
        }
      }

      img {
        @if $type =="" {
          border-radius: 20px 0 0 20px;
        }

        @media screen and (min-width: 1401px) {
          border-radius: 20px;
        }
      }

      &:before {
        @if $type ==header_sp {
          content: "";
          position: absolute;
          background: url(../images/common/shape_pink.png) no-repeat 0 0 / contain;
          z-index: -1;
          width: 296px;
          height: 298px;
          right: -105px;
          top: -180px;
          transform: rotate(35deg);
        }
      }
    }

    .lessonWrap {
      @if $type =="" {}

      @if $type ==header_sp {}

      @if $type ==header_pc {
        width: 48.58%;
        width: 680px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-inline: 12.1% 7.14%;
      }

      p.fontEn {
        @if $type =="" {
          color: #747474;
          font-weight: 600;
        }

        @if $type ==header_sp {
          font-size: 16px;
          padding-inline: 30px;
        }

        @if $type ==header_pc {
          font-size: 20px;
        }
      }

      h2 {
        @if $type =="" {
          letter-spacing: 0;
        }

        @if $type ==header_sp {
          font-size: 32px;
          line-height: 1.43;
          padding-inline: 30px;
          margin-top: 5px;
        }

        @if $type ==header_pc {
          font-size: 40px;
          line-height: 1.35;
        }
      }

      .description {
        @if $type =="" {
          margin-top: 25px;
          letter-spacing: 0em;
        }

        @if $type ==header_sp {
          padding-inline: 30px;
          line-height: 2.28;
        }

        @if $type ==header_pc {
          line-height: 2.25;
        }
      }


    }
  }
}

.aboutUs,
.lesson {
  .moreBtn {
    @if $type =="" {
      margin-top: 40px;
    }

    @if $type ==header_sp {
      padding-inline: 30px;
    }

    @if $type ==header_pc {}

    a {
      @if $type =="" {
        display: block;
        color: #fff;
        background: #000;
        height: 70px;
        line-height: 70px;
        border-radius: 5px;
        padding-inline: 35px;
        position: relative;
        text-align: center;
        border: 1px solid #000;
      }

      @if $type ==header_sp {}

      @if $type ==header_pc {
        transition: all .3s ease;
      }

      &:before {
        @if $type =="" {
          content: "";
          position: absolute;
          background: url(../images/common/arrow_white.svg) no-repeat 0 0 /contain;
          top: 50%;
          right: 25px;
          transform: translate(0, -50%);
        }

        @if $type ==header_sp {
          width: 20px;
          height: 20px;
        }

        @if $type ==header_pc {
          width: 24px;
          height: 24px;
        }
      }

      &:hover {
        @if $type ==header_pc {
          color: #000;
          background: #fff;

          &:before {
            background: url(../images/common/arrow_black.svg) no-repeat 0 0 /contain;
          }
        }
      }
    }
  }
}

.course {
  @if $type =="" {
    background-color: #000;
    color: #fff;
  }

  @if $type ==sp {
    padding-block: 55px 60px;
  }

  @if $type ==pc {
    padding-block: 95px 120px;
  }

  a {
    @if $type =="" {
      color: #fff;
    }
  }

  .lead {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}
  }

  .courseList {
    @if $type =="" {
      display: flex;
      gap: 20px;
    }

    @if $type ==sp {
      margin-top: 40px;
      flex-direction: column;
    }

    @if $type ==pc {
      margin-top: 45px;
      flex-wrap: wrap;
    }

    li {
      @if $type =="" {
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }

      @if $type ==sp {
        height: 100px;
        background-size: cover;
      }

      @if $type ==pc {
        width: calc((100% - 40px) / 2);
        height: 130px;
        background-size: 100% auto;
        transition: all .3s ease;
      }

      &:nth-of-type(1) {
        @if $type =="" {
          border: 1px solid $pink;
          background-image: url(../images/home/course01_thum_sp.webp);
        }

        // @if $type ==sp {
        //   background-image: url(../images/home/course01_thum_sp.webp);
        // }

        // @if $type ==pc {
        //   background-image: url(../images/home/course01_thum_pc.webp);
        // }
      }

      &:nth-of-type(2) {
        @if $type =="" {
          border: 1px solid $yellow;
          background-image: url(../images/home/course02_thum_sp.webp);
        }

        // @if $type ==sp {
        //   background-image: url(../images/home/course02_thum_sp.webp);
        // }

        // @if $type ==pc {
        //   background-image: url(../images/home/course02_thum_pc.webp);
        // }
      }

      &:nth-of-type(3) {
        @if $type =="" {
          border: 1px solid $green;
          background-image: url(../images/home/course03_thum_sp.webp);
        }

        // @if $type ==sp {
        //   background-image: url(../images/home/course03_thum_sp.webp);
        // }

        // @if $type ==pc {
        //   background-image: url(../images/home/course03_thum_pc.webp);
        // }
      }

      &:nth-of-type(4) {
        @if $type =="" {
          border: 1px solid $blue;
          background-image: url(../images/home/course04_thum_sp.webp);
        }

        // @if $type ==sp {
        //   background-image: url(../images/home/course04_thum_sp.webp);
        // }

        // @if $type ==pc {
        //   background-image: url(../images/home/course04_thum_pc.webp);
        // }
      }

      &:hover {
        @if $type ==pc {
          background-size: 110% auto;
        }
      }

      &:before {
        @if $type =="" {
          content: "";
          position: absolute;
          background: rgba($color: #000000, $alpha: 0.6);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        @if $type ==sp {}

        @if $type ==pc {}
      }

      &:after {
        @if $type =="" {
          content: "";
          position: absolute;
          background: url(../images/common/arrow_white.svg) no-repeat 0 0 /contain;
        }

        @if $type ==sp {
          width: 20px;
          height: 20px;
          right: 15px;
          bottom: 15px;
        }

        @if $type ==pc {
          width: 30px;
          height: 30px;
          right: 20px;
          bottom: 20px;
        }
      }


      a {
        @if $type =="" {
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          font-weight: 700;
          padding: 2vw;
          position: relative;
          z-index: 1;
        }

        @if $type ==sp {
          height: 100px;
        }

        @if $type ==pc {
          height: 130px;
          transition: all .3s ease;
        }

        p {
          @if $type =="" {
            position: relative;
            z-index: 2;
            text-align: center;
          }
        }
      }
    }
  }
}

.pageDoors {
  @if $type =="" {
    position: relative;
  }

  @if $type ==sp {
    margin-top: 60px;
  }

  @if $type ==pc {
    background: $gray;
  }

  &:before {
    @if $type =="" {
      content: "";
      position: absolute;
      background: url(../images/common/shape_green.png) no-repeat 0 0 / contain;
    }

    @if $type ==sp {
      width: 444px;
      height: 450px;
      left: -127px;
      bottom: -244px;
      transform: rotate(-25deg);
    }

    @if $type ==pc {
      width: 494px;
      height: 500px;
      bottom: -280px;
      right: min(19.2vw, 270px);
    }
  }


  ul {
    @if $type =="" {
      position: relative;
      z-index: 1;
    }

    @if $type ==sp {}

    @if $type ==pc {
      display: flex;
      max-width: 1400px;
      margin-inline: auto;
    }

    li {
      @if $type =="" {
        overflow: hidden;
        position: relative;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }

      @if $type ==sp {
        width: calc(100% - 30px);
        background-size: cover;
      }

      @if $type ==pc {
        width: calc(100% / 3);
        background-size: auto 100%;
        transition: all .3s ease;
      }

      +li {
        @if $type =="" {}

        @if $type ==sp {
          margin-top: 40px;
        }

        @if $type ==pc {}
      }

      &:nth-of-type(1) {
        @if $type =="" {
          background-image: url(../images/home/classroom_thum.webp);
        }

        @if $type ==sp {
          border-radius: 0 20px 20px 0;
        }
      }

      &:nth-of-type(2) {
        @if $type =="" {
          background-image: url(../images/home/fee_thum.webp);
          margin-left: auto;
        }

        @if $type ==sp {
          border-radius: 20px 0 0 20px;
        }
      }

      &:nth-of-type(3) {
        @if $type =="" {
          background-image: url(../images/home/voice_thum.webp);
        }

        @if $type ==sp {
          border-radius: 0 20px 20px 0;
        }
      }

      &:hover {
        @if $type ==pc {
          background-size: auto 110%;
        }
      }

      &:before {
        @if $type =="" {
          content: "";
          position: absolute;
          background: rgba($color: #000000, $alpha: 0.25);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        @if $type ==sp {}

        @if $type ==pc {}
      }

      &:after {
        @if $type =="" {
          content: "";
          position: absolute;
          background: url(../images/common/arrow_white.svg) no-repeat 0 0 /contain;
        }

        @if $type ==sp {
          width: 36px;
          height: 36px;
          right: 27px;
          bottom: 27px;
        }

        @if $type ==pc {
          width: 36px;
          height: 36px;
          right: 30px;
          bottom: 30px;
        }
      }

      a {
        @if $type =="" {
          display: block;
          color: #fff;
          position: relative;
          z-index: 1;
        }

        @if $type ==sp {
          height: 290px;
          padding: 27px 72px 27px 27px;
        }

        @if $type ==pc {
          height: 400px;
          padding: 30px 72px 30px 30px;
          transition: background-image .3s ease;
        }

        .wrap {
          @if $type =="" {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
          }

          p,
          h3 {
            @if $type =="" {
              position: relative;
              z-index: 10;
            }
          }

          p {
            @if $type =="" {
              font-weight: 600;
            }

            @if $type ==sp {}

            @if $type ==pc {
              margin-top: auto;
            }
          }

          h3 {
            @if $type =="" {
              font-size: 32px;
              line-height: 1.2;
              margin-top: 10px;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }

        }
      }
    }
  }
}


.results {
  @if $type =="" {
    position: relative;
    position: relative;
    z-index: 1;
  }

  @if $type ==sp {
    padding-block: 60px;
  }

  @if $type ==pc {
    padding-top: 120px;
  }

  &:before {
    @if $type =="" {
      content: "";
      position: absolute;
      background: $gray;
      width: 100%;
      bottom: 0;
      left: 0;
    }

    @if $type ==sp {
      height: 65%;
    }

    @if $type ==pc {}
  }

  .resultsInner {
    @if $type =="" {
      margin-inline: auto;
      position: relative;
      z-index: 2;
    }

    @if $type ==sp {
      width: calc(100% - 60px);
    }

    @if $type ==pc {
      width: calc(100% - 120px);
      max-width: 1060px;
    }


    p.fontEn {
      @if $type =="" {
        color: #747474;
        font-weight: 600;
      }

      @if $type ==sp {
        font-size: 16px;
      }

      @if $type ==pc {
        font-size: 20px;
      }
    }

    h2 {
      @if $type =="" {}

      @if $type ==sp {
        font-size: 32px;
        line-height: 1.43;
        letter-spacing: 0;
        margin-top: 5px;
      }

      @if $type ==pc {
        font-size: 40px;
        line-height: 1.35;
      }

      span {
        @if $type =="" {
          letter-spacing: -0.2em;
        }

        @if $type ==sp {}

        @if $type ==pc {}
      }
    }

    .container {
      @if $type =="" {}

      @if $type ==sp {
        margin-top: 28px;
      }

      @if $type ==pc {
        display: flex;
        margin-top: 32px;
      }

      .resultsMv {
        @if $type =="" {
          position: relative;
        }

        @if $type ==sp {
          margin-top: 30px;
        }

        @if $type ==pc {
          width: 47.16%;
          border-radius: 0 20px 20px 0;
          box-shadow: -170px 50px 0 0 $gray;
        }

        @media screen and (min-width: 1401px) {
          border-radius: 20px;
        }

        &:before {
          @if $type ==pc {
            content: "";
            position: absolute;
            background: url(../images/common/shape_blue.png) no-repeat 0 0 / contain;
            z-index: -1;
            width: 238px;
            height: 233px;
            bottom: -200px;
            left: 42px;
          }
        }

        img {
          @if $type ==sp {
            aspect-ratio: 370 / 150;
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: 50% 10%;
            border-radius: 10px;
          }

          @if $type ==pc {
            border-radius: 20px;
            // box-shadow: -170px 50px 0 0 $gray;
          }
        }
      }

      .resultsWrap {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
          width: calc(100% - 47.16%);
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 9.43%;
        }

        .best100 {
          @if $type =="" {
            width: 100%;
            max-width: 371px;
            height: auto;
          }
          @if $type ==sp {
            margin-top: 30px;
          }
          @if $type ==pc {
          }
        }

        .description {
          @if $type =="" {
            letter-spacing: 0em;
          }

          @if $type ==sp {
            margin-top: 12px;
            line-height: 2.28;
          }

          @if $type ==pc {
            margin-top: 15px;
            line-height: 2.25;
          }
        }

        .toArchive {
          @if $type =="" {
            text-align: right;
          }

          @if $type ==sp {
            margin-top: 25px;
          }

          @if $type ==pc {
            margin-top: 30px;
          }

          a {
            @if $type =="" {
              display: inline-block;
              position: relative;
              font-weight: 500;
            }

            @if $type ==sp {
              padding-left: 30px;
              line-height: 20px;
            }

            @if $type ==pc {
              padding-left: 38px;
              line-height: 24px;
              transition: all .3s ease;
            }

            &:before {
              @if $type =="" {
                content: "";
                position: absolute;
                background: url(../images/common/arrow_black.svg) no-repeat 0 0 / contain;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
              }

              @if $type ==sp {
                width: 20px;
                height: 20px;
              }

              @if $type ==pc {
                width: 24px;
                height: 24px;
              }
            }

            &:hover {
              @if $type ==pc {
                opacity: .5;
              }
            }
          }
        }
      }
    }
  }
}


.event {
  @if $type =="" {
    position: relative;
  }

  @if $type ==sp {
    padding-block: 60px;
  }

  @if $type ==pc {
    padding-block: 50px;
  }

  &:before {
    @if $type =="" {
      content: "";
      position: absolute;
      background: $gray;
      width: 100%;
      bottom: 0;
      left: 0;
    }

    @if $type ==sp {
      height: 55.9%;
    }

    @if $type ==pc {}
  }

  &:after {
    @if $type =="" {
      content: "";
      position: absolute;
      background: url(../images/common/shape_blue.png) no-repeat 0 0 / contain;
    }

    @if $type ==sp {
      width: 296px;
      height: 298px;
      right: -115px;
      top: -130px;
      transform: rotate(140deg) rotateY(180deg);
    }
  }

  .eventInner {
    @if $type =="" {
      margin-inline: auto;
      position: relative;
      z-index: 1;
    }

    @if $type ==sp {
      width: calc(100% - 60px);
    }

    @if $type ==pc {
      width: calc(100% - 120px);
      max-width: 1060px;
      margin-top: 120px;
    }

    .titileWrap {
      @if $type ==pc {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }


    p.fontEn {
      @if $type =="" {
        color: #747474;
        font-weight: 600;
      }

      @if $type ==sp {
        font-size: 16px;
      }

      @if $type ==pc {
        width: 47.16%;
        font-size: 20px;
      }
    }

    h2 {
      @if $type =="" {}

      @if $type ==sp {
        font-size: 32px;
        line-height: 1.43;
        letter-spacing: 0;
        margin-top: 5px;
      }

      @if $type ==pc {
        width: 47.16%;
        font-size: 40px;
        line-height: 1.35;
      }

      span {
        @if $type =="" {
          letter-spacing: -0.2em;
        }

        @if $type ==sp {}

        @if $type ==pc {}
      }
    }

    .container {
      @if $type =="" {}

      @if $type ==sp {
        margin-top: 28px;
      }

      @if $type ==pc {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 32px;
      }

      .eventMv {
        @if $type =="" {}

        @if $type ==sp {
          margin-top: 30px;
        }

        @if $type ==pc {
          width: 47.16%;
          border-radius: 20px 0 0 20px;
          box-shadow: 170px 50px 0 0 $gray;
        }

        @media screen and (min-width: 1401px) {
          border-radius: 20px;
        }

        img {
          @if $type ==sp {
            aspect-ratio: 370 / 150;
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: 50% 10%;
            border-radius: 10px;
          }

          @if $type ==pc {
            border-radius: 20px;
            // box-shadow: 170px 50px 0 0 $gray;
          }
        }
      }

      .eventWrap {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
          width: calc(100% - 47.16%);
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-right: 9.43%;
        }



        .description {
          @if $type =="" {
            letter-spacing: 0em;
          }

          @if $type ==sp {
            margin-top: 25px;
            line-height: 2.28;
          }

          @if $type ==pc {
            line-height: 2.25;
          }
        }

        .toArchive {
          @if $type =="" {
            text-align: right;
          }

          @if $type ==sp {
            margin-top: 25px;
          }

          @if $type ==pc {
            margin-top: 30px;
          }

          a {
            @if $type =="" {
              display: inline-block;
              position: relative;
              font-weight: 500;
            }

            @if $type ==sp {
              padding-left: 30px;
              line-height: 20px;
            }

            @if $type ==pc {
              padding-left: 38px;
              line-height: 24px;
              transition: all .3s ease;
            }

            &:before {
              @if $type =="" {
                content: "";
                position: absolute;
                background: url(../images/common/arrow_black.svg) no-repeat 0 0 / contain;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
              }

              @if $type ==sp {
                width: 20px;
                height: 20px;
              }

              @if $type ==pc {
                width: 24px;
                height: 24px;
              }
            }

            &:hover {
              @if $type ==pc {
                opacity: .5;
              }
            }
          }
        }
      }
    }
  }
}

.blog {
  @if $type =="" {}

  @if $type ==sp {
    padding-top: 60px;
  }

  @if $type ==pc {
    padding-top: 100px;
  }

  .commonInner {
    @if $type =="" {
      position: relative;
      z-index: 1;
    }
  }

  .blogListWrap {
    @if $type =="" {
      border: 1px solid #DADADA;
      border-radius: 10px;
      margin-top: 25px;
      background-color: #fff;
    }

    @if $type ==sp {
      padding-block: 25px;
      padding-inline: 25px;
    }

    @if $type ==pc {
      padding-block: 45px;
      padding-inline: 65px;
    }

    &:before {
      @if $type =="" {
        content: "";
        position: absolute;
        background: url(../images/common/shape_yellow.png) no-repeat 0 0 / contain;
        z-index: -1;
      }

      @if $type ==sp {
        width: 250px;
        height: 260px;
        left: -100px;
        bottom: -95px;
        left: -100px;
        bottom: -90px;
        transform: rotate(-210deg);
      }

      @if $type ==pc {
        width: 290px;
        height: 306px;
        top: 60px;
        right: 70px;
        top: -50px;
        right: -135px;
      }
    }


    .blogList {
      @if $type =="" {}

      @if $type ==sp {}

      @if $type ==pc {}

      li {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {}

        +li {
          @if $type =="" {
            position: relative;
          }

          @if $type ==sp {
            margin-top: 18px;
            padding-top: 20px;
          }

          @if $type ==pc {
            margin-top: 28px;
            padding-top: 30px;
          }

          &:before {
            @if $type =="" {
              content: "";
              position: absolute;
              background-color: #DADADA;
              width: 100%;
              height: 1px;
              top: 0;
              left: 0;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }

        }

        a {
          @if $type =="" {
            display: block;
          }

          @if $type ==sp {}

          @if $type ==pc {
            transition: all .3s ease;
          }

          &:hover {
            @if $type ==pc {
              opacity: .5;
            }
          }

          .date {
            @if $type =="" {
              font-weight: 700;
              @include fontEn;
            }

            @if $type ==sp {
              font-size: 16px;
            }

            @if $type ==pc {
              font-size: 18px;
            }
          }

          .blogTitle {
            @if $type =="" {
              margin-top: 5px;
            }

            @if $type ==sp {}

            @if $type ==pc {}
          }
        }
      }
    }

    .toArchive {
      @if $type =="" {
        text-align: right;
      }

      @if $type ==sp {
        margin-top: 30px;
      }

      @if $type ==pc {
        margin-top: 40px;
      }

      a {
        @if $type =="" {
          display: inline-block;
          position: relative;
          font-weight: 500;
        }

        @if $type ==sp {
          padding-left: 30px;
          line-height: 20px;
        }

        @if $type ==pc {
          padding-left: 38px;
          line-height: 24px;
          transition: all .3s ease;
        }

        &:before {
          @if $type =="" {
            content: "";
            position: absolute;
            background: url(../images/common/arrow_black.svg) no-repeat 0 0 / contain;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
          }

          @if $type ==sp {
            width: 20px;
            height: 20px;
          }

          @if $type ==pc {
            width: 24px;
            height: 24px;
          }
        }

        &:hover {
          @if $type ==pc {
            opacity: .5;
          }
        }
      }
    }
  }
}

.instagram {
  @if $type =="" {
    position: relative;
  }

  @if $type ==sp {
    padding-block: 60px;
  }

  @if $type ==pc {
    padding-block: 120px;
  }

  &:before {
    @if $type =="" {
      content: "";
      position: absolute;
      background: $gray;
      width: 100%;
      bottom: 0;
      left: 0;
    }

    @if $type ==sp {
      height: 44.6%;
    }

    @if $type ==pc {
      height: 42.2%;
    }
  }

  .commonInner {
    @if $type =="" {
      position: relative;
      z-index: 1;
    }
  }

  picture {
    img {
      @if $type =="" {
        display: block;
        margin-top: 30px;
      }

      @if $type ==sp {}

      @if $type ==pc {}
    }
  }
}